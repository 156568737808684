import { Spacer, Stack } from '@qasa/ui'
import { useTranslation } from 'react-i18next'

import type { SearchHistoryProps } from '../search-history'
import { SearchHistoryHeading } from '../search-history.parts'
import { useSearchHistoryContext } from '../search-history-context'

import { PreviousSearchesListItem } from './previous-searches-list-item'

export function PreviousSearches({ onListItemPress }: SearchHistoryProps) {
  const { t } = useTranslation('find_home', { keyPrefix: 'search_history' })
  const { previousSearches } = useSearchHistoryContext()
  if (!previousSearches?.length) {
    return null
  }

  return (
    <Stack>
      <SearchHistoryHeading>{t('previous_searches.title')}</SearchHistoryHeading>
      <Spacer size="4x" />
      {previousSearches.map((previousSearch, index) => (
        <PreviousSearchesListItem
          previousSearch={previousSearch}
          onListItemPress={onListItemPress}
          key={index}
        />
      ))}
    </Stack>
  )
}
