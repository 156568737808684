import { styled } from '@qasa/ui/web'
import { forwardRef, type Ref } from 'react'

import { getSearchQueryString } from '../filters/utils/search.utils'
import { Link } from '../../../vendor/next'
import type { FindHomeFilterValues } from '../filters/use-find-home-filters'

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingInline: theme.spacing['4x'],
  paddingBlock: theme.spacing['2x'],
  minHeight: theme.sizes['16x'],
  gap: theme.sizes['4x'],
  flex: 1,
  minWidth: 0,
  borderRadius: theme.radii.full,
  textDecoration: 'none',
  [theme.mediaQueries.mdUp]: {
    minHeight: theme.sizes['12x'],
    ':hover': {
      backgroundColor: theme.colors.core.offWhite,
    },
  },
}))

type SearchHistoryLinkProps = {
  filterValues: Partial<FindHomeFilterValues>
  onClick?: () => void
  children?: React.ReactNode
}

export const SearchHistoryLink = forwardRef(
  (props: SearchHistoryLinkProps, forwardedRef?: Ref<HTMLAnchorElement>) => {
    const { filterValues, onClick, children } = props
    const search = getSearchQueryString({ filterValues })
    return (
      <StyledLink href={`/find-home${search}`} ref={forwardedRef} onClick={onClick}>
        {children}
      </StyledLink>
    )
  },
)
