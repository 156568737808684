import {
  IconButton,
  MoreVerticalIcon,
  BookmarkIcon,
  DropdownMenu,
  BellIcon,
  BellOffIcon,
  TrashIcon,
} from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'
import { useTranslation } from 'react-i18next'

import { SearchHistorySummary } from '../search-history.parts'
import { SearchHistoryLink } from '../search-history-link'

import { DeleteSavedSearch } from './delete-saved-search'
import { useSavedSearchesListItem, type SavedSearchesListItemProps } from './use-saved-searches-list-item'

const SavedSearchItemWrapper = styled('li')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const DropdownTriggerButton = styled(IconButton)(({ theme }) => ({
  '&[data-state=open]': {
    backgroundColor: theme.colors.core.gray20,
  },
}))

export function SavedSearchesListItem({ savedSearch, onListItemPress }: SavedSearchesListItemProps) {
  const { t } = useTranslation('find_home', { keyPrefix: 'saved_searches' })
  const {
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    savedSearchFilters,
    searchSummary,
    updateSavedSearch,
    isMonitoring,
  } = useSavedSearchesListItem({ savedSearch, onListItemPress })

  const handleToggleMonitoringPress = (event: Event) => {
    event.preventDefault()
    updateSavedSearch()
  }

  const handleDeletePress = () => setIsDeleteDialogOpen(true)

  return (
    <SavedSearchItemWrapper>
      <SearchHistoryLink onClick={onListItemPress} filterValues={savedSearchFilters}>
        <BookmarkIcon size={16} style={{ flexShrink: 0 }} />
        <SearchHistorySummary searchSummary={searchSummary} />
      </SearchHistoryLink>
      <DropdownMenu>
        <DropdownMenu.Trigger>
          <DropdownTriggerButton icon={MoreVerticalIcon} label="View options" />
        </DropdownMenu.Trigger>
        {/* NOTE: needed this zIndex since the default one is lover than the dialog one */}
        <DropdownMenu.Content style={{ zIndex: 1500 }} className="saved-search-dropdown-menu">
          <DropdownMenu.Item
            onSelect={handleToggleMonitoringPress}
            icon={isMonitoring ? BellIcon : BellOffIcon}
          >
            {isMonitoring
              ? t('toggle_monitoring.turn_off_monitoring')
              : t('toggle_monitoring.turn_on_monitoring')}
          </DropdownMenu.Item>
          <DropdownMenu.Item onSelect={handleDeletePress} icon={TrashIcon}>
            {t('delete.trigger_button')}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>
      <DeleteSavedSearch
        isOpen={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
        savedSearch={savedSearch}
      />
    </SavedSearchItemWrapper>
  )
}
