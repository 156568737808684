import { Divider, Stack } from '@qasa/qds-ui'

import { SavedSearches } from './saved-searches/saved-searches'
import { RecommendedSearches } from './recommended-searches'
import { PreviousSearches } from './previous-searches'
import { useSearchHistoryContext } from './search-history-context'

export type SearchHistoryProps = {
  onListItemPress?: () => void
}
export function SearchHistory({ onListItemPress }: SearchHistoryProps) {
  const { savedSearches, previousSearches } = useSearchHistoryContext()

  return (
    <Stack gap="8x">
      <SavedSearches onListItemPress={onListItemPress} />
      {Boolean(savedSearches?.length) && <Divider />}
      <PreviousSearches onListItemPress={onListItemPress} />
      {Boolean(previousSearches?.length) && <Divider />}
      <RecommendedSearches onListItemPress={onListItemPress} />
    </Stack>
  )
}
